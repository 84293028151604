import { DOCUMENT, NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  imports: [NgClass],
})
export class AppModalComponent {
  @Input() sourceId = '';
  @Input() title = '';
  @Input() keepBackDrop: boolean | undefined;
  backdropId = 'modalBackdrop';
  #document = inject(DOCUMENT);

  @Input() closeBackDrop: boolean = false;
  @Input() modalClass: string = '';
  @Input() showTitle: boolean = true;
  @Output() modalCloseEvent = new EventEmitter<unknown>();

  close() {
    this.showOffCanvas();
    if (!this.keepBackDrop) {
      this.showBackdrop();
    }
    this.#document.body.classList.remove('modal-open');
    this.modalCloseEvent.emit();
  }

  private showOffCanvas() {
    const target = this.#document.getElementById(this.sourceId);
    if (!target) {
      return;
    }
    target.classList.remove('show');
  }

  private showBackdrop() {
    const backdrop = this.#document.getElementById(this.backdropId);
    if (!backdrop) {
      return;
    }
    backdrop.classList.remove('show');
  }

  show() {
    const target = this.#document.getElementById(this.sourceId);
    if (!target) {
      return;
    }
    target.classList.add('show');
    const backdrop = this.#document.getElementById('modal-backdrop');
    if (this.closeBackDrop && backdrop) {
      backdrop.classList.add('show');
    }
    this.#document.body.classList.add('modal-open');
    this.#document.body.appendChild(target);
  }
}
