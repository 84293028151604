import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Product, ProductDescription } from '@scalefast/core';
import { GenericLogger } from '@scalefast/ecommerce-core';

/**
 * Pipe to translate a product field based on the current locale
 * @deprecated Move to pandora:
 *   the decodeKnownEntities should be in a service for specific implementation
 */
@Pipe({
  name: 'translateProductField',
  standalone: true,
})
export class TranslateProductFieldPipe implements PipeTransform {
  private logger = inject(GenericLogger);
  private locale = inject(LOCALE_ID);

  transform(product: Product, field: keyof Omit<ProductDescription, 'seo' | 'sections'>): string {
    const lang = this.locale.split('-')[0].toUpperCase();

    const translatedObject = product.descriptions.find((item) => item.lang === lang);
    if (translatedObject?.[field]) {
      return this.decodeEntities(translatedObject[field]);
    } else {
      if (translatedObject) {
        this.logger.error(
          `No translated field found for ${field} on productId ${product.id} in translated object ${JSON.stringify(translatedObject)}`,
        );
      }
      this.logger.warn(
        `No translated field found for ${field} in ${lang}, on productId ${product.id} returning first available field ${product.descriptions[0][field]}`,
      );
      return this.decodeEntities(product.descriptions[0][field]);
    }
  }

  private decodeEntities(text: string): string {
    const entities: Record<string, string> = {
      amp: '&',
      apos: "'",
      '#x27': "'",
      '#x2F': '/',
      '#39': "'",
      '#47': '/',
      lt: '<',
      gt: '>',
      nbsp: ' ',
      quot: '"',
      plus: '+',
    };
    return text.replace(/&([^;]+);/gm, (match, entity) => {
      return entities[entity] ?? match;
    });
  }
}
