<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  data-testid="modal-container"
  aria-hidden="true"
  [id]="sourceId"
>
  @if (closeBackDrop) {
    <div id="modal-backdrop" class="modal-backdrop" tabindex="-1" (click)="close()" (keypress)="close()"></div>
  }
  <div class="modal-dialog" [ngClass]="modalClass">
    <div class="modal-content">
      <div class="modal-header">
        @if (showTitle) {
          <h5 class="modal-title">{{ title }}</h5>
        }
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          data-testid="modal-dismiss"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-body">
        <ng-content />
      </div>
    </div>
  </div>
</div>
